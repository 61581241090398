import { useParams } from '@solidjs/router';
import { JSX, Show, createSignal, onMount } from 'solid-js';
import 'tailwindcss/tailwind.css';
import {
  I_HAVE_READ_AND_AGREED_TO_THE_TERMS,
  TRANS_UNION_TERMS,
  MAGICDOOR_TERMS,
  WELCOME_TO_MAGIC_DOOR,
  MAGIC_DOOR_PROVIDES_A_PLATFORM,
  RENT_COLLECTION,
  MAINTENANCE_MANAGEMENT,
  TENANT_COMMUNICATION,
  FINANCIAL_REPORTING,
  RENTAL_APPLICATION_CHARGES_NON_REFUNDABLE,
  VIEW_IN_FULL_SCREEN,
} from '~/assets/strings';
import ActivityIndicator from '~/components/common/activity-indicator/ActivityIndicator';
import usePresenter from '~/framework/hooks/usePresenter';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { StepFooter } from '~/pages/rental-application/steps/StepFooter';
import { RentalApplicationPresenter } from '~/presenters/RentalApplicationPresenter';
import { RentalApplicationSettingsPresenter } from '~/presenters/RentalApplicationSettingsPresenter';
import { RentalApplicationSteps } from '~/types/RentaApplicationSteps';
import { UpdateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/updateRentalApplicationInfoUseCase';
import { ValidateRentalApplicationInfoUseCase } from '~/use-cases/rental-applications/application-steps/validateRentalApplicationInfoUseCase';
import { GetRentalApplicationUseCase } from '~/use-cases/rental-applications/getRentalApplicationUseCase';
import { GoToNextStepUseCase } from '~/use-cases/rental-applications/goToNextStepUseCase';
import { GoToPrevStepUseCase } from '~/use-cases/rental-applications/goToPrevStepUseCase';

export const TermsAndConditions = () => {
  const params = useParams();
  const { t } = useLocalization();
  const [isGoingToNextStep, setIsGoingToNextStep] = createSignal(false);
  const { model: presentableRentalApplicationSettings } = usePresenter(RentalApplicationSettingsPresenter);
  const { model: presentableRentalApplication } = usePresenter(RentalApplicationPresenter);
  const { execute: getRentalApplication, isLoading: isLoadingApplication } = useUseCase(GetRentalApplicationUseCase);
  const { execute: updateRentalApplication } = useUseCase(UpdateRentalApplicationInfoUseCase);
  const { execute: goToPrevStep } = useUseCase(GoToPrevStepUseCase);
  const { execute: goToNextStep, didSucceed: didSucceedGoingNext } = useUseCase(GoToNextStepUseCase);
  const { execute: validateRentalApplication, didSucceed: didSucceedValidation } = useUseCase(ValidateRentalApplicationInfoUseCase);

  const TRANSUNION_PDF_URL = '/TransUnionTerms.pdf#view=FitH&toolbar=0&navpanes=0&scrollbar=0';
  const magicDoorTermsContent = (
    <>
      <p>{t(WELCOME_TO_MAGIC_DOOR)}</p>
      <br />
      <p>{t(MAGIC_DOOR_PROVIDES_A_PLATFORM)}</p>
      <ul>
        <li>- {t(RENT_COLLECTION)}</li>
        <li>- {t(MAINTENANCE_MANAGEMENT)}</li>
        <li>- {t(TENANT_COMMUNICATION)}</li>
        <li>- {t(FINANCIAL_REPORTING)}</li>
      </ul>
      <br />
      <p>{t(RENTAL_APPLICATION_CHARGES_NON_REFUNDABLE)}</p>
    </>
  );

  const onAcceptedTransUnionTerms = (hasAccepted: boolean) => {
    updateRentalApplication({ hasAcceptedTransUnionTerms: hasAccepted });
  };

  const onAcceptedMagicDoorTerms = (hasAccepted: boolean) => {
    updateRentalApplication({ hasAcceptedMagicDoorTerms: hasAccepted });
  };

  const onPrev = async () => {
    await goToPrevStep({
      currentStep: RentalApplicationSteps.TERMS_AND_CONDITIONS,
      credentials: { id: params.id, password: params.password },
    });
  };

  const onNext = async () => {
    await validateRentalApplication();
    if (!didSucceedValidation() || presentableRentalApplication()?.errors?.hasLicenseAgreementErrors) {
      return;
    }
    setIsGoingToNextStep(true);
    await goToNextStep({
      currentStep: RentalApplicationSteps.TERMS_AND_CONDITIONS,
      credentials: { id: params.id, password: params.password },
    });
    if (!didSucceedGoingNext()) {
      setIsGoingToNextStep(false);
    }
  };

  onMount(async () => {
    await getRentalApplication({ id: params.id, password: params.password });
  });

  return (
    <Show
      when={!isLoadingApplication() && !isGoingToNextStep()}
      fallback={
        <div class="flex h-section3">
          <ActivityIndicator />
        </div>
      }>
      <div class="scrollbar flex h-section3 flex-col overflow-y-auto bg-white p-5">
        <div class="grow">
          <TermsAndConditionSections
            title={t(MAGICDOOR_TERMS)}
            content={magicDoorTermsContent}
            accepted={presentableRentalApplication()?.hasAcceptedMagicDoorTerms}
            onAcceptChanged={onAcceptedMagicDoorTerms}
            error={presentableRentalApplication()?.errors?.magicDoorLicenseError}
          />
          <Show when={presentableRentalApplicationSettings()?.requiresScreening}>
            <TermsAndConditionSections
              title={t(TRANS_UNION_TERMS)}
              iframe={TRANSUNION_PDF_URL}
              accepted={presentableRentalApplication()?.hasAcceptedTransUnionTerms}
              onAcceptChanged={onAcceptedTransUnionTerms}
              error={presentableRentalApplication()?.errors?.transUnionLicenseError}
            />
          </Show>
        </div>
      </div>
      <StepFooter onNext={onNext} onPrevious={onPrev} />
    </Show>
  );
};

type TermsAndConditionsProps = {
  title: string;
  content?: string | JSX.Element;
  iframe?: string;
  accepted?: boolean;
  error?: string;
  onAcceptChanged: (hasAccepted: boolean) => void;
};

const TermsAndConditionSections = (props: TermsAndConditionsProps) => {
  const { t } = useLocalization();

  const [iframeRef, setIframeRef] = createSignal<HTMLIFrameElement>();

  const openInNewTab = () => {
    if (iframeRef()) {
      window.open(props.iframe, '_blank');
    }
  };

  return (
    <div class="h-fit overflow-hidden rounded-lg p-2.5">
      <div class="flex items-center justify-between">
        <h2 class="mb-2 text-lg font-semibold text-primary-color">{props.title}</h2>
        <Show when={props.iframe}>
          <button id="view-in-fullscreen" type="button" class="text-sm text-primary-color underline" onClick={openInNewTab}>
            {t(VIEW_IN_FULL_SCREEN)}
          </button>
        </Show>
      </div>
      <Show when={props.iframe}>
        <iframe ref={setIframeRef} src={props.iframe} class="h-modalSm w-full rounded-lg bg-input p-2" />
      </Show>
      <Show when={props.content}>
        <div class="scrollbar h-fit overflow-auto rounded-lg bg-input px-4 py-3 text-sm text-text-level01 ">{props.content}</div>
      </Show>
      <div class="mb-4">
        <div class="flex flex-col pt-3">
          <label class="flex items-center">
            <input
              id="accepted"
              type="checkbox"
              checked={props.accepted}
              onInput={() => props.onAcceptChanged(!props.accepted)}
              class="form-checkbox size-5 text-blue-600"
            />
            <div class="ml-2 text-sm">{t(I_HAVE_READ_AND_AGREED_TO_THE_TERMS)}</div>
          </label>
          <Show when={props.error}>
            <div class="text-s my-1 text-red-500">{props.error}</div>
          </Show>
        </div>
      </div>
    </div>
  );
};
